import styled, { css } from 'styled-components';
import { generateBreakpoint } from '../layouts/grid';
import ascii from '@src/images/404/404_ascii.svg';

export const StyledPopup = styled.div`
    height: 40vw;
    width: 80vw;
    ${generateBreakpoint('md')(
        css`
            height: 30vw;
            width: 60vw;
        `,
    )}

    ${generateBreakpoint('lg_xl')(
        css`
            height: 20vw;
            width: 40vw;
        `,
    )}
`;

export const Styled404 = styled.div`
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: calc(100vh - 58px - 425px);

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${ascii});
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: 90% 20%;

    ${generateBreakpoint('md')(
        css`
            height: calc(100vh - 88px - 100px);
        `,
    )}
`;
