import React from 'react';
import { Link } from 'gatsby';
import { Styled404, StyledPopup } from '@templates/404.styled';
import popupError from '@src/images/404/popup-error.svg';

const NotFoundPage = () => (
    <Styled404>
        <StyledPopup>
            <Link to="/">
                <img
                    src={popupError}
                    alt="404 popup message, click to go to home"
                />
            </Link>
        </StyledPopup>
    </Styled404>
);

export default NotFoundPage;
